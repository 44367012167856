import { GlobalStrings } from '../../../common/fragments/MRKConfig';

export interface SysInfo {
  contentTypeId: string;
}
export interface MediaAsset {
  file?: {
    publicURL: string;
  };
}
export type ReferenceContentTypes = 'page' | 'mrkPage' | 'caseStudy';
export interface Page extends SysInfo {
  kind: 'page';
  relativePath: string;
}

export interface MrkPage extends SysInfo {
  kind: 'mrkPage';
  url_pageUrl: string;
}

export interface CaseStudy extends SysInfo {
  kind: 'caseStudy';
  nameUrlSlug: string;
}

export type ResourceReference = Page | MrkPage | CaseStudy;

export interface Resource {
  id: string;
  title: string;
  resourceReference?: ResourceReference;
  resourceType: string;
  resourceTypeCustom?: string;
  callToAction:
    | 'Learn More'
    | 'Download PDF'
    | 'Download Image'
    | 'Watch Video'
    | 'Register'
    | 'Register Now!'
    | 'Watch Now'
    | 'Tune In'
    | 'Add to Calendar';
  callToActionDestination?: string;
  resourceStreamId?: string;
  industry?: string[];
  topic: string[];
  orderDate: string;
  language?: string[];
  resourceTitle: string;
  resourceMedia: MediaAsset;
  description?: string;
  externalLinkUrl?: string;
  hideFromResourceHub?: boolean;
  livestart?: string;
  goodafter?: string;
  displaytimezonecd?: string;
  filters?: {
    industries: string[];
    topics: string[];
    resourceTypes: string[];
    language: string[];
  };
}

export const resourceCTAToGrobalStringsKeysMapping: {
  [K in Resource['callToAction']]: keyof GlobalStrings['json_values'];
} = {
  'Learn More': 'Learn_More',
  'Download PDF': 'Download_PDF',
  'Download Image': 'Download_Image',
  'Watch Video': 'Watch_Video',
  'Watch Now': 'Watch_Now',
  'Register Now!': 'Register_Now_',
  'Tune In': 'Tune_In',
  'Add to Calendar': 'Add_to_Calendar',
  Register: 'Register',
};
